/*
 * dashboard css File
*/

.dashboard {
    /* propertys here */
}

.dash_container {
    padding-top: 10px;
    background: #dde1ec
}

.Leftpanel {
    overflow: hidden;
    box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.2);
    background: #eff3fc
}

.leftpanelhead {
    padding: 15px;
    background: url(../../images/left_head_bg.png) right bottom no-repeat #5339d9;
}

.prodile_image {
    width: 69px;
    height: 69px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border: 2px solid #fff;
    overflow: hidden;
    flex: 0 0 69px;
    max-width: 69px;
    margin-right: 15px;
}

.profile_desc {
    flex: 0 0 auto;
    color: #fff
}

.profile_desc h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 3px
}

.profile_desc h6 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 3px
}

.profile_desc h5 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 3px
}

.member_since {
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: 400;
    margin: 5px 0 0;
}

.leftpanelbody {
    padding: 15px 0px;
    margin-top: -15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.leftpanelbody .side_panel_nav {
    background: none;
    padding: 0;
}

.leftpanelbody .side_panel_nav .nav-item .nav-link {
    padding: 18px 26px;
    color: #4d4d4d;
    font-weight: 600
}

.left_panel_nav {
    list-style: none;
    padding-left: 0;
    width: 100%
}

.left_panel_nav li {
    padding: 0;
    position: relative;
    overflow: hidden;
}

.left_panel_nav li:after {
    border-bottom: 1px solid #d7d7d7;
    content: "";
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
}

.left_panel_nav li:last-child:after {
    content: none;
}

.left_panel_nav li a {
    color: #3c4349;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}

.left_panel_nav li a svg {
    fill: #3c4349;
    margin-right: 10px;
}

.left_panel_nav li a.active,
.left_panel_nav li a:active,
.left_panel_nav li a:hover,
.left_panel_nav li a:focus {
    color: #4177b7 !important;
}

.left_panel_nav li a.active svg,
.left_panel_nav li a:active svg,
.left_panel_nav li a:hover svg,
.left_panel_nav li a:focus svg {
    fill: #4177b7
}

.card {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}


.donation_left {
    flex: 1 1 auto;
    padding: 25px
}

.donation_card h3 {
    font-size: 20px;
    color: #48535b;
    font-weight: 700;
    margin-bottom: 15px;
}

.donation_card strong {
    font-size: 16px;
    font-weight: 600;
    color: #48535b
}

.donation_card p {
    font-size: 15px;
    font-weight: 400;
    color: #48535b;
    margin-bottom: 10px;
}

.donation_card .progress {
    height: .5rem;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    margin: 15px 0 20px
}

.donation_card .progress-bar {
    background-color: #5238da;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.sponsorship_block h6 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
    color: #5339d9;
}

.sponsorship_block p {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0;
    color: #5339d9;
}

.donation_block {
    text-align: right;
    color: #48535b
}

.donation_block h6 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
    color: #48535b;
    text-align: right;
}

.donation_block p {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0;
    color: #48535b;
    text-align: right;
}

.donation_right {
    flex: 0 0 256px;
    border-left: 1px solid #e9eff3;
    padding: 25px
}

.donation_right_top h2 {
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    color: #20313d;
    margin-bottom: 0
}

.donation_right_top h2 span {
    font-weight: 700;
    font-size: 23px;
    color: #20313d
}

.redemption_code strong {
    font-size: 16px;
    font-weight: 600;
    color: #48535b;
    margin-bottom: 20px;
}

.r_code {
    background: #f5f3ff;
    border: 1px solid #e7e5f1;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #000;
    text-align: center;
    line-height: 25px;
    padding: 7px 34px;
    margin: 5px 0;
    flex:1 1 auto;
    font-weight: 600;
}

.counter {
    width: 86px;
    height: 86px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 32, 84, .2);
    border: 3px solid #6d57d8;
    text-align: center;
    line-height: 74px;
    font-size: 40px;
    font-weight: 600;
    color: #6d57d8;
    margin-bottom: 15px;
}

.peope_ben_count p {
    text-align: center;
    font-size: 14px;
}

.sponsorship_list {
    font-size: 15px;
    color: #6d57d8;
    text-decoration: none;
}

.sponsorship_list:hover {
    text-decoration: none;
}

@media screen and (max-width:767px) {
    .donation_card .card .d-flex {
        flex-direction: column;
    }

    .donation_right {
        border-left: 0;
        border-top: 1px solid #e9eff3;
    }

    .dash_body .card {
        margin-right: 0 !important;
    }

    .sidebar {
        padding-right: 0px !important;
    }
}

.pimage {
    max-height: 100px;
}

.progress {
    background-color: #65e41e !important;
}





.profile_box {
    background: #fff;
    width: 80%;
    height: 200px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 30px auto 60px;
    position: relative;
    box-shadow: 0 20px 17px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile_box:before {
    content: "";
    width: 90%;
    height: 8px;
    background: rgba(255, 255, 255, .9);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    bottom: -8px;
    left: 5%;
    z-index: 1;
    box-shadow: 0 2px 3px #c6c6c61a;
}

.profile_box:after {
    content: "";
    width: 80%;
    height: 16px;
    background: #f6f6f7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    bottom: -16px;
    left: 10%;
}

.profile_box .profile_img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #3EB2C5;
    overflow: hidden;
}

.profile_box h5 {
    margin: 8px 0 0;
    font-weight: 400;
}

.profile_box h6 {
    margin-bottom: 0
}


.dash_body .card {
    position: relative;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    overflow: hidden;
    box-shadow: none;
    background: #fff;
}

/* .dash_body .card:after {
    content: "";
    top: -1px;
    right: -1px;
    border-top: 50px solid #eff3fc;
    border-left: 50px solid transparent;
    position: absolute;
    right: 0;
    top: 0
} */


.accordion li span {
    padding: .5rem 26px .5rem 20px;
    font-weight: 600;
    display: block;
    color: #4d4d4d;
    text-transform: uppercase;
}

.accordion li span svg {margin-right: 8px;}

.accordion li span:hover,
.accordion li span:focus {
    color: #4177b7;
}

.delete_image {
    cursor: pointer;
    top: 2px;
    position: absolute;
    margin-left: 64px;
    background: white;
    border-radius: 50%;
}



/************* Dip New Design Start  ***********/

.eBook_block {
    background: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
    overflow: hidden;
    flex-wrap: wrap;
    height: 100%;
}

.eBook_image {
    flex: 0 0 100%;
    height: 250px;
    position: relative;
}

.eBook_image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.eBook_title {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: #1aa4b998;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    text-align: center;
    font-size: 30px;
    color: #fff;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.eBook_desc {
    flex: 0 0 100%;
    padding: 15px 15px 30px;
    text-align: justify;
}

.eBook_desc .eBook_download {
    border-radius: 50px;
    width: 150px;
    height: 45px;
    color: #fff;
    line-height: 45px;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: auto;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    background: rgb(4,64,126);
    background: linear-gradient(117deg, rgba(4,64,126,1) 0%, rgba(4,64,126,1) 50%, rgba(75,159,217,1) 50%, rgba(22,102,182,1) 62%, rgba(4,64,126,1) 81%);
    text-decoration: none;
    transition: all 1s ease-in-out;
    text-align: center;
}

.eBook_desc .eBook_download:hover { background:#04407e ;}


.ebooks_heading {
    font-size: 25px;
    color: #1aa3b9;
    position: relative;
}
.ebooks_desc {
    font-size: 18px;
    color:rgb(28, 28, 28)
}
/************* Dip New Design End  ***********/


.navbar-nav svg {width: 20px; height: 20px;}
.h-80 {height: 80%;}
.search_icon {fill:#fff}



.pagination_ebooks {
    margin: 0;
    padding: 0;
    text-align: center
  }
  .pagination_ebooks li {
    display: inline;
    padding: 10px;
  }
  .pagination_ebooks li a {
    display: inline-block;
    text-decoration: none;
    padding: 5px 10px;
    color: #000
  }
  
  /* Active and Hoverable Pagination */
  .pagination_ebooks li a {
    border-radius: 5px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s
      
  }
  .pagination_ebooks li a.active {
    background-color: #4caf50;
    color: #fff
  }
  .pagination_ebooks li a:hover:not(.active) {
    background-color: #221DFF;
    color: #ddd;
  } 
  .pagination_ebooks_active{
    background-color: #221DFF;
    color: #ddd;
  }
  li.previous.disabled{
    pointer-events: none;
  }
  li.next.disabled{
    pointer-events: none;
  }