.otp_btn{
    height: 40px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 28px;
    /* background: #00bcd4; */
    /* color: #fff; */
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    background: #00bcd4;
    transition: all 0.3s ease;
    /* box-shadow: 0 5px 3px rgb(0 0 0 / 40%); */
    border-radius: 8px;
    border: 1px solid #1AA3B9;
    text-transform: uppercase;
}
.otp_field{
    flex: 1 1;
}
.field_box{
    display: flex;
    flex-wrap: wrap;
}
.verified_otp_btn{
    position: relative;
    height: 40px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 28px;
    background: green;
    color: #fff;
    transition: all 0.3s ease;
    /* box-shadow: 0 5px 3px rgb(0 0 0 / 40%); */
    border-radius: 8px;
    border: 1px solid green;
    text-transform: uppercase;
}
.error_btn_fix{
    position: relative;
    height: 40px;
    /* margin-left: 10px !important;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 28px; */
    background: #D4403A;
    color: #fff;
    transition: all 0.3s ease;
    /* box-shadow: 0 5px 3px rgb(0 0 0 / 40%); */
    border-radius: 8px;
    border: 1px solid #D4403A;
    text-transform: uppercase;
}
.verified_otp_btn::before{
    content: url(../.././images/right_sign.png);
    border-radius: 8px;
    border: 1px solid green;
    text-transform: uppercase;
    transition: all 0.3s ease;

}
.verified_otp_btn:hover
 {
  background: green !important;
  color: #fff !important; 
}
.otp_btn:hover
 {
  background: #17b2d8 !important;
  color: #fff !important;
  border-color: #17b2d8 !important;  
}
.error_btn_fix:hover{
    color: #fff !important;
}
/* .country_code_field{
    border: 0px;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: 37px;
     margin: 29px -4px 1px 15px
} */

.payable_ammount strong{
    font-size: 27px ;
    color: #1aa3b9;
    text-shadow: 0 3px 5px #909ea8;
}

.payable_ammount span{
    font-size: 22px ;
    color: #221DFF;
    font-weight: 600;
}