/*
 * middleSection css File
*/



@font-face {
    font-family: 'gbtw';
    src: url('fonts/gbtw.eot?o7e5hd');
    src: url('fonts/gbtw.eot?o7e5hd#iefix') format('embedded-opentype'),
        url('fonts/gbtw.ttf?o7e5hd') format('truetype'),
        url('fonts/gbtw.woff?o7e5hd') format('woff'),
        url('fonts/gbtw.svg?o7e5hd#gbtw') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.gbtwi {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'gbtw' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.gbtwi:before {
    content: "\e800";
}

.middleSection {
    /* propertys here */
}

.card.mycard {
    /* height: 400px; */
    /* overflow-y: scroll; */
    margin-bottom: 50px;
    border: 2px solid #F5F8FD;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    box-shadow: 0 8px 9px #63848a
}

.mycard h2 {
    font-weight: 600;
    font-size: 30px;
    color: #2E2B71;
    position: relative;
    margin-bottom: 30px;
    display: inline-block;
    align-self: flex-start
}

.mycard h2:after {
    content: "";
    width: 100%;
    height: 3px;
    background: #2E2B71;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: -10px;
}


.mycard h3 {
    font-weight: 600;
    font-size: 20px;
    color: #2E2B71;
    display: inline-block
}

.mycard h3 a {
    text-decoration: none;
}


.mycard h4 {
    font-weight: 600;
    font-size: 20px;
    color: #2E2B71
}

.mycard p {
    font-size: 16px;
    color: #272727
}


.mycard .job_card,
.mycard .freebook_card {
    box-shadow: 0 5px 15px rgba(146, 184, 190, 0.8)
}


.btn-1 {
    /*font-family: Hack, monospace;
    */
    /* background: #1AA3B9; */
    color: #ffffff;
    cursor: pointer;
    font-size: 1.5em;
    padding: .5rem 2.5rem;
    border: 0;
    transition: all 0.5s;
    border-radius: 5px;
    position: relative;
    text-decoration: none;
    line-height: 24px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-top: 20px;
    display: inline-block;
    float: right;

    background: #1e5799;
    background: -moz-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%);
    background: -webkit-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%);
    background: linear-gradient(to bottom, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#7db9e8', GradientType=0);


}



.btn-1::after {
    content: "";
    font-weight: 400;
    position: absolute;
    left: auto;
    top: 23%;
    right: 5%;
    bottom: 0;
    opacity: 0;
    color: #ffffff;
    background: url(../../../images/right.png) left center no-repeat;
    width: 24px;
    height: 24px;
}

.btn-1:hover {
    /* background: #2b2bff; */
    transition: all 0.5s;
    border-radius: 10px;
    box-shadow: 0px 6px 15px #0000ff61;
    padding: .5rem 3.5rem .5rem 3.5rem;
    color: #ffffff;
    text-decoration: none;
    background: #7db9e8;
    background: -moz-linear-gradient(top, #7db9e8 0%, #207cca 49%, #2989d8 50%, #1e5799 100%);
    background: -webkit-linear-gradient(top, #7db9e8 0%, #207cca 49%, #2989d8 50%, #1e5799 100%);
    background: linear-gradient(to bottom, #7db9e8 0%, #207cca 49%, #2989d8 50%, #1e5799 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7db9e8', endColorstr='#1e5799', GradientType=0);

}

.btn-1:hover::after {
    opacity: 1;
    transition: all 0.5s;
    color: #ffffff;

}

.resume_img {
    position: relative;
}

.btn-1.rc_button {
    position: absolute;
    right: 60px;
    bottom: 60px;
    padding: 8px 15px;
}

.btn-1.rc_button:hover::after {
    content: none
}



.job_card {
    overflow: hidden;
}

.lifeimage_block {
    height: 240px;
    display: flex;
    align-items: center;
    background: #000;
}

.lifeimage_block img {
    max-height: 100%
}

.job_card .card-title {
    font-size: 17px;
    text-align: center
}


.freebook_card .card-title {
    font-size: 15px;
    text-align: left;
    font-weight: 600;
    color: #1AA3B9
}

.freebook_card .card-body {
    padding: 10px 8px
}

.freebook_card .card-body .card-text {
    font-size: 14px;
}


/* .jobaltitems {
    padding: 5px 15px;
    background: #fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    margin-bottom: 10px
} */



.jobaltitems p {
    margin-bottom: 0;
    font-size: 14px;
}

/* .jobaltitems p b {
    color: #006DCE;
} */

@media screen and (max-width:767px) {
    .mycard {
        padding-top: 300px !important;
    }
}







.l_logo_block {
    margin: 0 auto;
    width: 300px;
    position: relative;
    height: 100%
}

.l_logo_block:after {
    position: absolute;
    content: "";
    width: 1px;
    top: 0;
    bottom: 0;
    background: #c6c6c6;
    left: 18px;
    z-index: 1;
}



.l_logo_block a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border: 1px solid rgb(233, 233, 233);
    height: 200px;
    width: 200px;
    background: #fff;
    margin: 0 auto;
}


.l_logo_block a .img_round {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    box-shadow: 0 8px 9px #63848a;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
}



.l_logo_block a:after {
    content: "\2713";
    width: 20px;
    height: 20px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border: 1px solid #c6c6c6;
    position: absolute;
    left: -42px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    background: #fff;
    z-index: 2;
    box-shadow: 0 0 10px #63848a;
    text-align: center;
    line-height: 20px;
    color: #fff
}

.l_logo_block a img {
    max-width: 70%;
    max-height: 70%
}

.l_logo_block a.active:after {
    background: #5CEA93;
    padding: 5px;
    border: 2px solid #5CEA93;
    box-shadow: 0 0 10px #5CEA93;
    color: #fff;
    text-align: center;
    line-height: 16px;
    padding: 0
}


.l_logo_block a.active {
    box-shadow: 0 8px 9px #63848a;
}

.l_logo_block a .active_check {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    box-shadow: 0 4px 4px #63848a;
    background: #fff;
    position: absolute;
    left: 15px;
    top: 15px;

}

.l_logo_block a.active .active_check,
.l_logo_block a #active {
    background: #5CEA93;
}

.l_logo_block a.active .active_check svg {
    margin: 0px 4px
}

.list_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 40px;
    text-align: center;
    background: #F5F8FD
}

.list_footer span {
    color: #c4c4c4;
    font-size: 10px;
}

.list_footer h6 {
    color: #363636;
    font-size: 12px;
    margin-bottom: 0;
}

.list_footer .col:first-child {
    border-right: 1px solid #e6e2e2
}










.progress.round {
    width: 150px;
    height: 150px;
    line-height: 150px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
    background: transparent !important;
}

.progress.round:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid #eee;
    position: absolute;
    top: 0;
    left: 0;
}

.progress.round>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.progress.round .progress-left {
    left: 0;
}

.progress.round .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: #2246D6;
}

.progress.round .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 75px;
    border-bottom-right-radius: 75px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.progress.round .progress-right {
    right: 0;
}

.progress.round .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 75px;
    border-bottom-left-radius: 75px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
}

.progress.round .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 36px;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    width: 82%;
    height: 82%;
    font-weight: 300;
    margin: 13px auto;
    box-shadow: 0 8px 5px #1AA3B9;
}

.progress.round .progress-value div {
    margin-top: 10px;
}

.progress.round .progress-value span {
    font-size: 12px;
    text-transform: uppercase;
}

/* This for loop creates the 	necessary css animation names 
  Due to the split circle of progress-left and progress right, we must use the animations on each side. 
  */
.progress.round[data-percentage="10"] .progress-right .progress-bar {
    animation: loading-1 1.5s linear forwards;
}

.progress.round[data-percentage="10"] .progress-left .progress-bar {
    animation: 0;
}

.progress.round[data-percentage="20"] .progress-right .progress-bar {
    animation: loading-2 1.5s linear forwards;
}

.progress.round[data-percentage="20"] .progress-left .progress-bar {
    animation: 0;
}

.progress.round[data-percentage="30"] .progress-right .progress-bar {
    animation: loading-3 1.5s linear forwards;
}

.progress.round[data-percentage="30"] .progress-left .progress-bar {
    animation: 0;
}

.progress.round[data-percentage="40"] .progress-right .progress-bar {
    animation: loading-4 1.5s linear forwards;
}

.progress.round[data-percentage="40"] .progress-left .progress-bar {
    animation: 0;
}

.progress.round[data-percentage="50"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
}

.progress.round[data-percentage="50"] .progress-left .progress-bar {
    animation: 0;
}

.progress.round[data-percentage="60"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
}

.progress.round[data-percentage="60"] .progress-left .progress-bar {
    animation: loading-1 1.5s linear forwards 1.5s;
}

.progress.round[data-percentage="70"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
}

.progress.round[data-percentage="70"] .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.5s;
}

.progress.round[data-percentage="80"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
}

.progress.round[data-percentage="80"] .progress-left .progress-bar {
    animation: loading-3 1.5s linear forwards 1.5s;
}

.progress.round[data-percentage="90"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
}

.progress.round[data-percentage="90"] .progress-left .progress-bar {
    animation: loading-4 1.5s linear forwards 1.5s;
}

.progress.round[data-percentage="100"] .progress-right .progress-bar {
    animation: loading-5 1.5s linear forwards;
}

.progress.round[data-percentage="100"] .progress-left .progress-bar {
    animation: loading-5 1.5s linear forwards 1.5s;
}

@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(36);
        transform: rotate(36deg);
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(72);
        transform: rotate(72deg);
    }
}

@keyframes loading-3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(108);
        transform: rotate(108deg);
    }
}

@keyframes loading-4 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(144);
        transform: rotate(144deg);
    }
}

@keyframes loading-5 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180);
        transform: rotate(180deg);
    }
}

.progress.round {
    margin-bottom: 1em;
}





.comitems {
    flex-wrap: wrap;
    max-width: 220px
}

.comitems .comitem {
    max-width: 100px;
    flex: 0 0 50%;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    box-shadow: 0 0 5px #c6c6c6;
    margin: 5px;
    margin-left: 0;
}

.mycard h3.rs_job_head {
    flex: 0 0 220px;
    font-size: 18px;
    text-align: center
}

.rs_job_head strong {
    color: #5CEA93;
    margin-right: 20px;
    font-size: 30px;
}

.job_listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0;
    list-style: none;
}

.job_listing li {
    flex: 0 0 45%;
    font-size: 18px;
    color: #2E2B71;
    margin-bottom: 15px;
    font-weight: 600;
    padding-left: 20px;
    position: relative;
}

.job_listing li:after {
    content: "\2713";
    font-size: 20px;
    color: #5CEA93;
    position: absolute;
    left: 0;
    top: 0
}





.searchPeopleList {
    background: #fff;
    margin-top: 30px;
    padding: 25px 20px;
    border-radius: 25px;
    box-shadow: 0px 0px 5px #ccc;
    text-align: center;
    position: relative;
    transition: 0.3s all;
    /* height: 100%; */
}

.searchPeopleList .sImgHolder {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    margin-bottom: 0px;
    position: relative;
    margin-bottom: 15px;
}

.sImgHolder>img.sUserImg {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
}

.sImgHolder img.sbi {
    position: absolute;
    bottom: -5px;
    right: 10px;
}

.searchPeopleList h4 {
    font-size: 22px;
    color: #734d9d;
    margin-bottom: 0;
}

.searchPeopleList h6 {
    font-size: 16px;
    margin: 0;
    line-height: 24px;
    font-weight: 400;
    color: #000;
}

.searchPeopleList h5 {
    font-size: 15px;
    color: #a3a3a3;
    margin-bottom: 0;
}

.ratingView {
    color: #dfdfdf;
    font-size: 21px;
    margin: 5px 0px;
}

.ratingView .rActive {
    color: #ffd616;
}

.searchPeopleList p {
    font-size: 15px;
    line-height: 21px;
    min-height: 65px;
    max-height: 65px;
    overflow: hidden;
    margin-bottom: 60px;
}

.defaultBtn {
    text-decoration: none !important;
    background: #82bd51;
    display: inline-block;
    padding: 10px 30px;
    text-transform: uppercase;
    border-radius: 25px;
    color: #fff !important;
    border: none;
    transition: 0.3s;
    box-shadow: 0px 0px 6px rgb(119 119 119 / 34%);
}

.searchPeopleList .defaultBtn {
    box-shadow: 0px 0px 8px #ccc;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    width: 196px;
    bottom: 19px;
}

.searchPeopleList .defaultBtn img {
    position: relative;
    top: -2px;
    left: 0;
}





/************* New Design Start **************/
.jobseeker_block {
    padding: 30px 30px;
    background-color: #fff;
    /* box-shadow: 0px 0px 25px rgb(56 152 226 / 30%); */
    box-shadow: 0 15px 40px rgba(0, 0, 0, .15);
    border-radius: 10px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    transition: 0.5s all ease;
    /* height: 100%; 
    margin-top: 10px; */
}

#scrollDownToActiveService:hover .jobseeker_block {
    background-color: #90dfec;
    cursor: pointer;
}

/*.jobseeker_block:before {
    width: 110px;
    height: 110px;
    position: absolute;
    left: -50px;
    top: -50px;
    content: "";
    background-color: #e7f6f9;
    border-radius: 50%;
    opacity: 1;
    z-index: -1;
    transition: 0.5s all ease;
}

.jobseeker_block:hover:before {
    content: none;
} 

.jobseeker_block:after {
    width: 110px;
    height: 110px;
    position: absolute;
    right: -50px;
    bottom: -50px;
    content: "";
    background-color: #e7f6f9;
    border-radius: 50%;
    opacity: 1;
    z-index: -1;
    transition: 0.5s all ease;
}

.jobseeker_block:hover:after {
    background-color: #fff;
    opacity: 0.3;
    width: 420%;
    height: 420%;
}*/

.jobseeker_block .jobseeker_logo {
    width: 70px;
    height: 70px;
    line-height: 0;
    display: flex;
    position: relative;
    /* z-index: 1; */
    align-items: center;
    justify-content: center;
    margin: 0px auto 0px;
}

.jobseeker_block .jobseeker_logo [class*=icon-] {
    transition: 0.5s all ease;
}

/* .jobseeker_block:hover .jobseeker_logo [class*=icon-] {
    transform: scale(0.8);
} */

.jobseeker_block .jobseeker_logo:after {
    transition: 0.5s all ease;
    width: 90px;
    height: 90px;
    background-color: #fff;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.1);
    position: absolute;
    left: 50%;
    content: "";
    z-index: -1;
    top: 50%;
    opacity: 0;
}

.jobseeker_block .jobseeker_logo:after {
    transform: translateX(-50%) translateY(-50%) scale(1);
    opacity: 1;
    box-shadow: 0px 10px 25px rgb(0 0 0 / 20%);
}

.jobseeker_block p {
    color: rgb(33, 33, 33);
    font-weight: 500;
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
}

.jobseeker_block:hover p {
    color: #000;
}








.modal-backdrop {
    background: #000;
    opacity: .5 !important;
}

.jobseeker_modal .modal-dialog .modal-content {
    /* border-radius: 20px;
    box-shadow: 0 5px 15px rgba(42, 42, 42, 0.292);
    border: none; */
    /* box-shadow: 0 0 30px #00bcd4; */
    /* border: 12px solid rgb(170 170 170 / 42%); */
    border-radius: 40px;
    border: none;
    padding: 40px;

    background: url(../images/bundel_service_bg.jpg);
    background-size: cover;
}

.modal_overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg, #FFFFFF1C 37%, #FFFFFF 65%);
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    border-radius: 40px 40px 40px 40px;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.jobseeker_modal .close_btn {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    background: #e6192a;
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    font-weight: 800;
    position: absolute;
    right: -45px;
    top: -45px;
    cursor: pointer;
}

.jobseeker_modal .modal-body {
    padding: 30px;
}

.modal_heading {
    padding: 10px 10px 10px 10px;
    background-color: #0C2444;
    border-radius: 15px 15px 15px 15px;
    font-size: 40px;
    color: #fff;
    font-family: "Philosopher";
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.modal_heading img {
    background: #fff;
    padding: 8px;
    border-radius: 5px;
}

.modal_service_img {
    max-width: 60px;
    max-height: 60px;
}

.modal_heading h2 {
    margin-bottom: 0;
    margin-left: 15px;
    font-family: "Philosopher";
    font-family : "Poppins,sans-serif";
}

.modal_all_ex_bun p {
    color: #000000;
    font-family: "Roboto", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
    font-family : "Poppins,sans-serif";
}

.modal_all_ex_bun ul {
    list-style: none;
    padding-left: 0;
}

.modal_all_ex_bun ul li {
    position: relative;
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    padding-left: 25px;
}

.modal_all_ex_bun ul li:after {
    content: "\e800";
    font-family: 'gbtw';
    position: absolute;
    left: 0px;
    color: #000;
    top: 50%;
    transform: translateY(-50%);
}


.videoframe .video {
    margin: -324px 21px 0px 22px;
    padding: 0px 0px 0px 0px;
    background-color: #000000;
    position: relative;
    padding-bottom: 50.80%;
    ;
}

.videoframe .video iframe {
    max-width: 100%;
    width: 100%;
    margin: 0;
    line-height: 1;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}



@media screen and (min-width: 768px) and (max-width: 991px) {
    .videoframe .video {
        margin: -76% 8.2% 0px 8.2%;
        position: absolute;
        left: 0;
        right: 0;
    }
}

@media screen and (max-width: 767px) {
    .videoframe .video {
        margin: -77% 7% 0px 7%;
        position: absolute;
        left: 0;
        right: 0;
    }
}

/* .jobseeker_modal .modal-body .modal_jobseeker_title {
    font-size: 25px;
    color: #1ba3b9;
    font-weight: 600;
}

.jobseeker_modal .modal-body .seperator {
    width: 150px;
    border-bottom: 1px solid #1ba3b9;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 20px;
} */

.add_account {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 5px;
    color: #fff;
    background: #1ba3b9;
    padding: 5px 15px;
    transition: 0.5s all ease;
    border: none;
    min-height: 50px;
    font-weight: 600;
    min-width: 150px;
}

.add_account:hover,
.add_account:focus,
.add_account:active {
    background: #108193 !important;
    border: #108193 !important;
    outline: none !important;
    box-shadow: none;
}

.modal-90w {
    width: 90%;
    max-width: 980px !important;
}

.active_jobseeker {
    width: 9px;
    height: 9px;
    border-radius: 50px;
    background: #00A650;
    position: absolute;
    top: 20px;
    right: 20px;
}

.active_jobseeker:before {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background: white;
    z-index: -1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.active_jobseeker:after {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50px;
    border: 3px solid #00A650;
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.dactive_jobseeker {
    width: 9px;
    height: 9px;
    border-radius: 50px;
    background: transparent;
    position: absolute;
    top: 20px;
    right: 20px;
}

.dactive_jobseeker:after {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50px;
    border: 3px solid gray;
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.active_left_icon {
    width: 9px;
    height: 9px;
    position: absolute;
    top: 20px;
    left: 20px;
}

/************* New Design End **************/

.unpaidCover {
    position: relative;
}

.unpaidCover::after {
    content: "";
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    position: absolute;
    left: 15px;
    top: 0;
    right: 15px;
    bottom: 0;
    background: rgba(68, 68, 68, 0.4);
    z-index: 2;
    border-radius: 5px;
}

.btn_fix {
    position: relative;
}

.learn_btn {
    position: absolute;
    z-index: 9;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    border-radius: 50px;
    background: #1aa3b9;
    border: none;
    font-size: 16px;
    padding: 5px 10px;

}

.learn_btn:hover {
    background: #0c7080;
}

.scroll_btn {
    border: none;
    background: none;
}

.activetext {
    position: absolute;
    z-index: 9;
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
    text-align: center;
    width: 210px;
    color: #fff;
    font-size: 15px;
    border: 2px solid #221DFF;
    border-radius: 15px;
    background: #221DFF;
}

.tabclass {
    position: relative;
}

.activetextOn {
    position: absolute;
    z-index: 6;
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
    width: 185px;
    /* border: 2px solid #5fb63b;
    */
    text-align: center;
    color: #fff;
    font-size: 15px;
    border-radius: 15px;
    background: #5fb63b;
    padding: 0 15px;
}





.openai {
    /* background: #93ddea; */
    /* border: 3px solid #bababa;
    border-radius: 25px;
    box-shadow: 0 2px 10px rgb(138, 138, 138);
    margin-bottom: 40px;
    padding: 15px 30px; */



    padding: 15px 15px;
    background-color: #fff;
    box-shadow: 0 15px 40px rgba(0, 0, 0, .15);
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    transition: 0.5s all ease;


}

.openai_heading {
    background: #5fb63b;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgb(138, 138, 138);
    padding: 2px 15px;
    /* margin-bottom: 30px; */
    display: inline-block;
}

.openai_heading h4 {
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0;
}

.logo_con {
    /* background: #fff;  */
    padding: 10px;
    width: 200px;
}

.logo_con h5 {
    /* margin-top: 10px; */
    font-size: 14px;
    margin-bottom: 0;
    color: #221DFF;
}

.logo_con strong {
    color: #221DFF;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 20px;
}

.openai .col-md-4:nth-child(1) h5,
.openai .col-md-4:nth-child(1) strong {
    font-style: italic;
}

.openai .col-md-4.inactive {
    position: relative;
    padding: 30px;
    height: 290px;
}

.openai .col-md-4 {
    position: relative;
    padding: 30px;
    height: 290px;
}

.openai .col-md-3.inactive:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 0;
    right: 15px;
    bottom: 0;
    background: rgba(68, 68, 68, 0.4);
    border-radius: 10px;
}

.openai .col-md-4.inactive .logo_con img {
    filter: grayscale(8);
}

.openai .col-md-4.inactive .logo_con strong {
    color: rgba(0, 0, 0, .8);
}

.cover_letter_btn_section {
    border-radius: 50px;
    background: #1aa3b9;
    border: none;
    font-size: 14px;
    padding: 2px 10px;
}

.cover_letter_btn_section:hover {
    background: #0c7080;
}


.modal_form .form-group {
    display: flex;
    flex-direction: column;
}

.modal_form .form-group label {
    font-size: 18px;
    order: -1;
}

.modal_form .form-group label.optional {
    color: #38B4CD;
}

.modal_form .tabformbutton {
    max-width: 70%;
    min-width: 70%;
    height: 48px;
}

.modal_form input:focus+.flable,
.modal_form textarea:focus+.flable {
    color: #221DFF;
}

.modal_button_side_price {
    font-size: 24px;
    color: #4e54c8;
    padding: 0px 0px 0px 13px;
}

#scrollDownToActiveService {
    text-align: center;
}

.hover_cover {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .63);
    border-radius: 10px;
    z-index: 9;
    opacity: 0;
    transition: opacity .5s ease-in-out;
}

#scrollDownToActiveService:hover .hover_cover {
    opacity: 1;
}


.congra_head.container {
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 15px 40px rgba(0, 0, 0, .15);
    border-radius: 10px;
    position: relative;
    z-index: 1;
    /* overflow: hidden; */
    text-align: center;
    transition: 0.5s all ease-in-out;
    opacity: 1;
    margin-top: 30px;
}

.congra_head.hide { opacity: 0; display: none;}

.openai_right {
    flex: 1 1 auto
}


.topbar_active_package {
    background: #dddee0;
    padding: 10px 15px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    /* padding-left: 120px; */
}

.topbar_active_package h5 {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
}

.topbar_active_package .active_package_item {
    position: relative;
    overflow: hidden;
}
.topbar_active_package .active_package_item:hover{
    overflow:visible;
}

.topbar_active_package .active_package_item img {
    max-width: 60px;
    max-height: 50px
}

.topbar_active_package .active_package_item .active_package_item_details {
    position: absolute;
    z-index: 99;
    min-width: 150px;
    right: 0;
    display: flex;
    align-items: center;
    background: rgba(183, 239, 249, .88);
    border: 1px solid #b7b7b7;
    border-radius: 10px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, .15);
    padding: 10px 15px;
    display: none;
    opacity: 0;
    transition: opacity .5s ease-in-out;
}
.topbar_active_package span{
    color: #221DFF;
    font-weight:600;
}

.topbar_active_package .active_package_item:hover .active_package_item_details {
    opacity: 1;
    display: flex;
}

.topbar_active_package .active_package_item:hover .active_package_item_details {opacity: 1; display: flex;}
.closeHeading {
    position: absolute;
    right: -15px;
    top: -15px;
    width: 30px;
    height: 30px;
    background: red;
    border-radius: 50px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
}

.congra_head.hide {
    opacity: 0;
    display: none;
}
.openai_heading_inactive {
    background: #221DFF;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgb(138 138 138);
    padding: 2px 15px;
    /* margin-bottom: 30px; */
    display: inline-block;
}

.openai_heading_inactive h4 {
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0;
}