/*
 * payment css File
*/

.payment {
    /* propertys here */
}

.Paymentheader {
    /* min-height: 300px; */
    /* background: url(../../images/paymentheaderbg.png) center bottom no-repeat #1aa3b9; */
    /* background: #1aa3b9; */
    /* color: #000; */
    font-weight: 600;
    text-align: center;
    /* padding-top: 10rem;
    padding-bottom: 5rem; */
    padding: 30px 0 ;
}

.Paymentheader h3 {
    font-weight: 700;
    font-size: 32px;
    color: #00BCD4;
}

.Paymentheader p {
    font-weight: 500;
    font-size: 16px;
    color:#000
}



.tarifheader {
    background: url(../../images/tarif_head_bg.png) 30px 100% no-repeat;
}

.tariflist .col-md-4.center_tarif .card {
    margin-top: -60px;
}

.col-md-4 .card {
    background: #f8fafc;
    box-shadow: 0px 7px 12px rgb(3 59 95 / 2%);
}

.tarifheadleft {
    flex: 0 0 auto;
}

.tarifheadleft .tariftitle {
    font-size: 15px;
    color: #445561;
    text-transform: uppercase;
}

.tarifheadleft .tarifamt {
    font-size: 36px;
    color: #1aa3b9;
    font-weight: 700;
}

.tarifheadleft .tspnsor {
    font-size: 15px;
    color: #445561;
}

.tarifbadge {
    flex: 0 0 65px;
}

.tarifbody {
    background: #fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin-top: -5px;
    padding: 15px;
}

.tarifbody ul {padding-left: 20px }
.tarifbody ul li { list-style-image: url(../../images/check.png); color:#3c4349; font-size: 14px; margin-bottom: 15px; }


.tariflist {
    position: relative;
}
.tariflist row {
    margin-left: -10px;
    margin-right: -10px;
}
.pl__grid__card {
    /* background: rgba(191,224,249,1);
    border: solid 1px #b0d1ea; */
    /*background: rgba(141, 216, 229, 1);
     border: solid 1px #00BCD4; */
    background: rgb(0,188,212);
background: linear-gradient(0deg, rgba(0,188,212,1) 0%, rgba(229,251,255,1) 100%);
    padding: 10px 10px 25px 10px;
    border-radius: 10px;
    position: relative;
    margin-top: 80px;
}
.pl__sep {
    width: 50px;
    height: 1px;
    background-color: #242c2c;
    margin: 20px auto 30px;
}
.pl__grid.col-md-2 {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    position: relative;
    z-index: 1;
}
.pl__grid__card  a {
    text-decoration: none;
    padding: 10px 20px;
    position: relative;
    text-align: center;
    display: inline-block;
    color: #0C2448;
    font-size: 13px;
    border: 1px solid #000;
    border-radius: 5px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 500;
    transition: all .3s ease;
}
.pl__grid__card img {
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translateX(-50%);
}
.pl__grid__card  a:hover {
    background-color: #d0ebff;
}







.flip-box-inner-wrapper {
    min-height: 600px;
    position: relative;

}

.pl__grid .flip-box-back, 
.pl__grid .flip-box-front {
    transition: transform .4s cubic-bezier(.2,.85,.4,1.275);
    box-shadow: 0 0 15px #b1ebf6;
}

.pl__grid .flip-box-front { background: #fff; }

.pl__grid .flip-box-back {
    z-index: -1;
    transform: rotateY(-180deg);
}
.pl__grid:hover .flip-box-back, 
.pl__grid:focus .flip-box-back, 
.pl__grid:hover .flip-box-back {
    z-index: 1;
    transform: rotateY(0);
}





.pl__grid .flip-box-front {
    transform: rotateY(0);
    transition-duration: 0.4s;
}

.pl__grid:hover .flip-box-front, 
.pl__grid:focus .flip-box-front, 
.pl__grid:hover .flip-box-front {
    transform: rotateY(180deg);
    z-index: -1;
}


.pl__grid .flip-box-front,
.pl__grid .flip-box-back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 70px;
}

.flip-box-front_ext {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 70px;
}

.pl__grid .flip-box-front_ext {
    transition: transform .4s cubic-bezier(.2,.85,.4,1.275);
    box-shadow: 0 0 15px #b1ebf6;
}

.scElm ul {
    padding-left: 18px;
}
.scElm ul li:not(:last-child) {
    margin-bottom: 10px;
}

.img-flip{
    width:136px;
    height:160px;
}

.dash_container h3 {color:#00bcd4}
.dash_container h3 strong {font-size: 22px; color:#221DFF}




@media screen and (max-width:767px) {
    .tariflist .col-md-4.center_tarif .card {margin-top: 0}
}

@media screen and (min-width:768px) and (max-width:991px) {
    .tariflist .col-md-10 {flex: 0 0 100%; max-width:100%; }
    .tarifbadge {display: none;}    
}


/* exisiting plan css */

.patron_existing_plan{
    background: linear-gradient(0deg, rgba(229,251,255,1) 0%, rgba(0,188,212,1) 100%);
    padding: 20px 20px 25px 20px;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 15px #b1ebf6;
}

.patron_logo{
    max-width: 20%;
    width: 100%;
}

.patron_text{
    max-width: 80%;
    width: 100%;
}

.patron_text h4{
    color: #212529;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 22px;
}

.patron_text ul li{
    margin-bottom: 10px;
    font-size: 14px;
    color: #212529;
    font-weight: 400;
}

.patron_text ul{
    padding-left: 15px;
    margin-bottom: 0px;
}

.view_det_but a{
    text-decoration: none;
    padding: 15px 30px;
    position: relative;
    text-align: center;
    display: inline-block;
    color: #0C2448;
    font-size: 15px;
    border: 1px solid #000;
    border-radius: 5px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 500;
    transition: all .3s ease;
}

.view_det_but a:hover{
    background-color: #d0ebff;
}