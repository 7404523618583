/*
 * header css File
*/

.header {
    box-shadow: 0 0px 15px rgba(0, 0, 0, .03);
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 100%;
    z-index: 9;
    top: 0;
}

.header .navbar {
    padding: 6px 0;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 8px;
    padding-right: 26px;
    font-size: 15px;
    font-weight: 500;
    color: #1aa3b9;
    text-transform: uppercase;
}

.navbar-expand-lg .navbar-nav .nav-link.active,
.navbar-expand-lg .navbar-nav .nav-link:active,
.navbar-expand-lg .navbar-nav .nav-link:hover,
.navbar-expand-lg .navbar-nav .nav-link:focus {
    color: #fff;
}


.user_info a {}

.user_info img {
    max-width: 40px;
}

.user_info h5 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0;
}

.user_info span {
    color: #fff;
    font-size: 12px;
    margin-bottom: 0;
}

.user_info h6 {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400 !important;
}

.user_info .member_since {
    color: #fff;
    margin-top: 0
}


.btn_header {
    border: 1px solid #22c3dd;
    background: rgba(0, 0, 0, .6) !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    font-size: 13px;
    color: #22c3dd;
    padding: 8px 23px;
    font-weight: 500;
    text-transform: uppercase;
}

.btn_header:hover,
.btn_header:active,
.btn_header:focus,
.btn_header.active {
    background: #000 !important;
    color: #fff !important;
    border: 1px solid #22c3dd;

}

.imgSize {
    width: 143px;
    /* height: 40px; */
}

.info_white h5,
.info_white span {
    color: #fff !important;
}

.top_wallet {
    max-width: 35px;
    margin-right: 5px;
}


.amt_drop {
    position: relative;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.amt_drop:hover { background: #cecece; border-radius: 50px; 
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px; }

.details_drop {
    position: absolute;
    z-index: 99999;
    min-width: 315px;
    left: 0;
    top: 100%;
    display: flex;
    align-items: center;
    background: rgba(183, 239, 249, .88);
    border: 1px solid #b7b7b7;
    border-radius: 10px;
    box-shadow: 0 15px 40px rgb(0 0 0 / 15%);
    padding: 15px 15px;
    /* display: none;
    opacity: 0; */
    transition: opacity .5s ease-in-out;
    flex-direction: column;
}

.cBal {
    font-size: 35px;
    font-weight: 700;
    line-height: 28px;
}

.cBal span {
    font-size: 14px;
    line-height: 14px;
}

.amt_list {
    margin-top: 10px;
}

.amt_list span {
    padding: 5px 15px;
    font-weight: 500;
    font-size: 16px;
    border: 1px solid #221DFF;
    border-radius: 5px;
    margin: 0 5px;
}

.amt_list span:hover,
.amt_list span.active {
    background: #221DFF;
    color: #fff;
}

.cr_trans {
    border-radius: 50px;
    background: #1aa3b9;
    border: none;
    font-size: 16px;
    padding: 5px 10px;
    margin-top: 15px;
    color: #fff;
}

.cr_trans:hover {
    background: #0c7080;
    text-decoration: none;
    color: #fff;
}

.topbar_active_package span.close_details_drop {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: red;
    color: #fff;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    right: -15px;
    top: -15px;
}